require("@rails/ujs").start()
require("@rails/activestorage").start()

require('scss/application.scss')

import 'jquery'
import Plyr from 'plyr';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'owl.carousel';

import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.min.css"
flatpickr(".new-datepicker", {})

// Import any .js files using requires instead of import

require('../legacy/bootstrap.js')
require('../legacy/cookiebanner.js')
require('../legacy/flick.js')
require('../legacy/slick.js')
require('../legacy/matchheight.js')
require('../legacy/custom.js')
require('../legacy/audioplayer2.js')
require('../scv1/nav.js')
require('../scv1/flexslider.js')
require('../scv1/custom.js')
require('../scv1/playlist-edit.js')
require("../admin/jquery-ui.js");
