$(function () {

  var supportsAudio = !!document.createElement('audio').canPlayType;
  var playerselector = document.querySelector('#audio1');
  var gobacktrackuntil = null;
  var playerinit = false;
  // initialize plyr
  var player = new Plyr(playerselector, {
    controls: [
      'rewind',
      'play',
      'fast-forward',
      'progress',
      'current-time',
      'duration',
      'mute',
      'volume',
      'airplay',
    ]
  });

  // initialize playlist and controls
  var index = 0,
    playing = false,
    mediaPath = 'https://stream.mux.com/',
    extension = '.m3u8',
    trackCount = $('#plList li').length,
    npArtist = $('#npArtist'),
    npTitle = $('#npTitle'),
    audio = $('#audio1').on('play', function () {
      playing = true;
    }).on('pause', function () {
      playing = false;

    }).on('ended', function () {

      if ((index + 1) < trackCount) {
        index++;
        playTrack(index);
      } else {
        audio.pause();
      }
    }).get(0),
    btnPrev = $('#btnPrev').on('click', function () {
      skipBack(index);
    }),
    btnNext = $('#btnNext').on('click', function () {
      skipForward(index);
    }),
    li = $('#plList li').on('click', function (e) {
      console.log(e.target)
      if (e.target.classList.contains("icon-Add") || e.target.classList.contains("dropdown-item") || e.target.classList.contains("dropdown-item")) {
      } else {
        var id = parseInt($(this).index());
        console.log(id);

        playTrack(id);

      }
    }),
    newPlaylist = $(".new-playlist").on('click', function () {
      $("#playlist-name").val("Untitled playlist");
      $("#new-playlist-save").show();
      $("#create-playlist-message-p").html("");
      $("#name-form").show();
      $("#playlist-first-track").val($(this).data("slug"));
      console.log($(this).data("slug"));
      $("#newPlaylistModal").modal();
      $("#new-playlist-save").on("click", function () {
        $("#new-playlist-save").hide();
        let title = $("#playlist-name").val();
        let track = $("#playlist-first-track").val();
        let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        console.log(track);
        $("#name-form").hide();
        $("#create-playlist-message-p").html(`Please wait  <span class="fa fa-spin fa-spinner"></span>`);
        fetch('/playlist/jsoncreate', {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            // add the token to the 'X-CSRF-Token' header
            'X-CSRF-Token': token
          },
          body: JSON.stringify({ "title": title, "tracks": [track] })  // convert the JavaScript object into a JSON string
        })
          .then(response => {
            if (!response.ok) {
              return response.json().then(err => {
                let errorMessage = err.message;  // Access the 'message' field from the response
                throw new Error(`${errorMessage}`);
              });
            }
            return response.json();
          })
          .then(data => {
            $("#create-playlist-message-p").html("Your playlist has been successfully created!");
          })
          .catch(error => {
            console.log(error);
            $("#create-playlist-message-p").html(error.message);
          });
      })
    }),
    Playlist = $(".existing-playlist").on('click', function () {
      $("#playlist-list").html(`Loading <span class="fa fa-spin fa-spinner"></span>`);
      $("#existing-playlist-message-p").html("");
      $("#existingPlaylistModal").modal();
      $("#track-to-add").val($(this).data("slug"));
      let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');


      fetch('/playlistsjson', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // add the token to the 'X-CSRF-Token' header
          'X-CSRF-Token': token
        },
        credentials: 'same-origin'
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          var playlistList = $('#playlist-list'); // get the container
          playlistList.empty(); // clear it out in case there are existing elements

          data.forEach(function (playlist) {
            // create new button for each playlist
            var div = $('<div></div>')
              .addClass('playlist-item p-2')
              .html(playlist.title)
              .css('cursor', 'pointer')
              .on('click', function () {
                playlistList.empty();
                let track = $("#track-to-add").val();
                $("#existing-playlist-message-p").html(`Please wait  <span class="fa fa-spin fa-spinner"></span>`);
                // handle click event here, for example:
                fetch("/playlist/" + playlist.slug + "/jsonadd", {
                  method: 'POST',
                  credentials: 'same-origin',
                  headers: {
                    'Content-Type': 'application/json',
                    // add the token to the 'X-CSRF-Token' header
                    'X-CSRF-Token': token
                  },
                  body: JSON.stringify({ "tracks": [track] })  // convert the JavaScript object into a JSON string
                })
                  .then(response => {
                    if (!response.ok) {
                      return response.json().then(err => {
                        let errorMessage = err.message;  // Access the 'message' field from the response
                        throw new Error(`${errorMessage}`);
                      });
                    }
                    return response.json();
                  })
                  .then(data => {
                    $("#existing-playlist-message-p").html("Track added successfully!");
                  })
                  .catch(error => {
                    $("#existing-playlist-message-p").html(error.message);
                  });
              });
            // append the new button to the list
            playlistList.append(div);

          });
        })
        .catch(error => {
          console.log(error);
          $("#create-playlist-message-p").html(error.message);
        });
    }),

    loadTrack = function (id) {
      if (typeof gtag !== 'undefined') {
        gtag('event', 'play', {
          'event_category': 'Audio', 'event_label': $('#plList li:eq(' + id + ')').data('clientref') + "_"
            + $('#plList li:eq(' + id + ')').data('title') + "_ISRC:" + $('#plList li:eq(' + id + ')').data('isrc')
        });
        console.log("Logged play:" + $('#plList li:eq(' + id + ')').data('clientref') + "_" + $('#plList li:eq(' + id + ')').data('title') + "_ISRC:" + $('#plList li:eq(' + id + ')').data('isrc'));
      }

      $('.plSel').removeClass('plSel');
      $('#plList li:eq(' + id + ')').addClass('plSel');
      npTitle.text($('#plList li:eq(' + id + ')').data("title"));
      npArtist.text($('#plList li:eq(' + id + ')').data("performer"));


      index = id;
      if (playerselector) {



        var newSource = document.querySelector('#audio1');
        newSource.src = mediaPath + $('#plList li:eq(' + id + ')').data('assetid') + "/audio.m4a" + "?token=" + $('#plList li:eq(' + id + ')').data('jwt');
        newSource.type = 'audio/m4a';
      }



    },
    playTrack = function (id) {
      if (playerselector) {
        loadTrack(id);
        audio.play();
      }
    };
  if (playerselector) {
    loadTrack(index);
  }

  function skipBack(index) {
    if ((index - 1) > -1) {
      var d = new Date();
      var n = d.getTime();
      if (gobacktrackuntil > n) {
        index--;
      }
      loadTrack(index);
      audio.play();
      gobacktrackuntil = (n + 1000);
    } else {
      audio.pause();
      index = 0;
      loadTrack(index);
    }
  }

  function skipForward(index) {
    if ((index + 1) < trackCount) {
      index++;
      loadTrack(index);

      audio.play();

    } else {
      audio.pause();
      index = 0;
      loadTrack(index);
    }
  }

  function loadMediaSession(index) {

  }

});
