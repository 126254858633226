// General JS file


//Pause Sentry JS logging
//Sentry.init({ dsn: 'https://dd93d73f8d7945829a3bfd44565f389d@o321841.ingest.sentry.io/5246307' });

$(function() {
  $('.topslider').on('init', function(event, slick){
    $('.topslider').css("opacity", "1");
  });
    $('.topslider').slick({
    autoplay: true,
    infinite: true,
    useTransform: false,
    centerPadding: '200px',
    slidesToShow: 1,
    arrows: false,
    fade: true,
    speed: 2000,
    autoplaySpeed: 1000,
    responsive: [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 1,
          centerPadding: '200px',
    }
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 1,
          centerPadding: '100px',

    }
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
          centerPadding: '30px',

    }
  }],
  });

 $('.owl-carousel').owlCarousel({
    autoWidth:true,
    dots: false,
    margin: 10,
    nav: true,
    navText: [
        '<i class="fa fa-angle-left" aria-hidden="true"></i>',
        '<i class="fa fa-angle-right" aria-hidden="true"></i>'
    ]
});

  $(".center-caption").height(($(".topslider").width()*0.42));

    $('.panel').matchHeight();
    $('.preview-text').matchHeight();

    $('.live-playpause').click(function () {
        if($(this).siblings("#mux-player").get(0).paused){
          console.log("Play");
            $(this).siblings("#mux-player").get(0).play();
            $(this).fadeOut();
        }else{
            console.log("Pause");
           $(this).siblings(".live-player").get(0).pause();
            $(this).fadeIn();
        }
    });

    var video = document.getElementById('mux-player');

  if(video) {
    var loggedplay=false;
video.addEventListener('play', (event) => {

    $(".live-playpause").fadeOut();
    if (typeof gtag !== 'undefined' && !loggedplay) {
      gtag('event', 'play', {'event_category': 'Live', 'event_label': $(video).data('clientref')+"_"+$(video).data('assetid')+"_"+$(video).data('title')});
      console.log("Logged play:"+$(video).data('clientref')+"_"+$(video).data('assetid')+"_"+$(video).data('title'));
      loggedplay=true;
    }

});
}


});
