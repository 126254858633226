$(document).ready(function() {
    // Delete function
    $('.delete-song').on('click', function(e) {
      e.preventDefault();
      $(this).parent().parent().siblings().children('.destroy-field').val('1');
      $(this).parents(".playlist-track-edit-card").fadeOut();
    });
  
    // Sort function
    $("#playlist-items-sort").sortable({
        update: function(event, ui) {
            $(this).children(".card").each(function(index) {
                console.log(this);
              if ($(this).find('.sort-field').length > 0) {
                $(this).find('.sort-field').val(index + 1);
              }
            });
          }
    });
  });

