/*  Table of Contents
01. MENU ACTIVATION
02. MOBILE NAVIGATION ACTIVATION
03. FLEXSLIDER LANDING PAGE
04. SCROLL TO TOP BUTTON
05. Registration Page On/Off Clickable Items
*/

$(function() {
	 'use strict';

/*
=============================================== 01. MENU ACTIVATION  ===============================================
*/
	  $('nav#site-navigation-pro ul.sf-menu, nav#sidebar-nav ul.sf-menu').superfish({
			 	popUpSelector: 'ul.sub-menu, .sf-mega', 	// within menu context
	 			delay:      	200,                	// one second delay on mouseout
	 			speed:      	0,               		// faster \ speed
		 		speedOut:    	200,             		// speed of the closing animation
				animation: 		{opacity: 'show'},		// animation out
				animationOut: 	{opacity: 'hide'},		// adnimation in
		 		cssArrows:     	true,              		// set to false
			 	autoArrows:  	true,                    // disable generation of arrow mark-up
		 		disableHI:      true,
	 });



	 /* Sticky Landing Page Header */

	 $('header.sticky-header').scrollToFixed({
		 minWidth:768
	 });

	 /* Remove Fixed Heading on Mobile */
	 /*
 	$(window).resize(function() {
 	   var width_progression = $(document).width();
 	      if (width_progression < 768) {
			  $('header.sticky-header').trigger('detach.ScrollToFixed');
		  }
 	}).resize();
	*/

	 /* Sitcky Video Sidebar */
	 $('nav#sidebar-nav.sticky-sidebar-js').hcSticky({
		 top:0
	 });



/*
=============================================== 02. MOBILE NAVIGATION ACTIVATION  ===============================================
*/
 	$('#mobile-bars-icon-pro').click(function(e){
 		e.preventDefault();
 		$('#mobile-navigation-pro').slideToggle(350);
 		$("header#masthead-pro").toggleClass("active-mobile-icon-pro");
		$("header#videohead-pro").toggleClass("active-mobile-icon-pro");
 	});


   	$('ul#mobile-menu-pro').slimmenu({
   	    resizeWidth: '90000',
   	    collapserTitle: 'Menu',
   	    easingEffect:'easeInOutQuint',
   	    animSpeed:350,
   	    indentChildren: false,
   		childrenIndenter: '- '
   	});

/*
=============================================== 03. FLEXSLIDER LANDING PAGE  ===============================================
*/
     $('.progression-studios-slider').flexslider({
 		slideshow: true,  		/* Autoplay True/False */
 		slideshowSpeed: 8000,	/* Autoplay Speed */
 		animation: "fade",		/* Slideshow Transition Animation */
 		animationSpeed: 800, 	/* Slide Transition Speed */
 		directionNav: true,		/* Left/Right Navigation True/False */
 		controlNav: true,		/* Bullet Navigaion True/False */
 		prevText: "",
 		nextText: "",
     });


/*
=============================================== 04. SCROLL TO TOP BUTTON  ===============================================
*/

   	// browser window scroll (in pixels) after which the "back to top" link is shown
   	var offset = 150,

 	//browser window scroll (in pixels) after which the "back to top" link opacity is reduced
   	offset_opacity = 1200,

 	//duration of the top scrolling animation (in ms)
   	scroll_top_duration = 700,

 	//grab the "back to top" link
   	$back_to_top = $('#pro-scroll-top');

 	//hide or show the "back to top" link
 	$(window).scroll(function(){
   		( $(this).scrollTop() > offset ) ? $back_to_top.addClass('cd-is-visible') : $back_to_top.removeClass('cd-is-visible cd-fade-out');
   		if( $(this).scrollTop() > offset_opacity ) {
   			$back_to_top.addClass('cd-fade-out');
   		}
   	});

 	//smooth scroll to top
 	$back_to_top.on('click', function(event){
 		event.preventDefault();
 		$('body,html').animate({ scrollTop: 0 , }, scroll_top_duration
 	);
 	});


/*
=============================================== 05. Registration Page On/Off Clickable Items  ===============================================
*/

	$("ul.registration-invite-friends-list li").click(function() {
	    $(this).closest("ul.registration-invite-friends-list li").toggleClass("active");
	});

	$("ul.registration-genres-choice li").click(function() {
	    $(this).closest("ul.registration-genres-choice li").toggleClass("active");
	});

});

/*  Table of Contents
01. FLEXSLIDER DASHBOARD SLIDER
02. Video App Header On/Off Clickable Items
03. Video App Header On/Off Clickable Items
04. Range Slider in Header Search
05. Add to Favorites Menu ON/Off
06. Rating Selector
*/

jQuery(document).ready(function($) {
	 'use strict';




/*
=============================================== 01. FLEXSLIDER DASHBOARD SLIDER  ===============================================
*/
     $('.progression-studios-dashboard-slider').flexslider({
 		slideshow: true,  		/* Autoplay True/False */
 		slideshowSpeed: 5000,	/* Autoplay Speed */
 		animation: "fade",		/* Slideshow Transition Animation */
 		animationSpeed: 800, 	/* Slide Transition Speed */
 		directionNav: true,		/* Left/Right Navigation True/False */
 		controlNav: true,		/* Bullet Navigaion True/False */
 		prevText: "",
 		nextText: "",
     });


/*
=============================================== 02. Video App Header On/Off Clickable Items  ===============================================
*/
	$("#header-user-profile-click").click(function() {
		var $this = $("#header-user-profile");
	    if ($this.hasClass('active')) {
	        $this.removeClass('active').addClass('hide');
	    } else {
	        $this.addClass('active');
	    }
	});


	$("#header-user-notification-click").click(function() {
		var $this = $("#header-user-notification");
	    if ($this.hasClass('active')) {
	        $this.removeClass('active').addClass('hide');
	    } else {
	        $this.addClass('active');
	    }
	});


	$("#search-icon-more").click(function() {

	});

	/* If clicking outside of boxes, automatically hide */
	$(document).click(function(e) {
	    if (e.target.id != 'header-user-profile' && !$('#header-user-profile').find(e.target).length) {
	        if ($("#header-user-profile").hasClass('active')) {
	        	$("#header-user-profile").removeClass('active').addClass('hide');
	        }
	    }

	    if (e.target.id != 'header-user-notification' && !$('#header-user-notification').find(e.target).length) {
	        if ($("#header-user-notification").hasClass('active')) {
	        	$("#header-user-notification").removeClass('active').addClass('hide');
	        }
	    }

	    if (e.target.id != 'video-search-header' && !$('#video-search-header').find(e.target).length) {
	        if ($("#video-search-header").hasClass('active')) {
	        	$("#video-search-header").removeClass('active').addClass('hide');
						$("#search-bar").val("");
	        }
	    }
	});

/*
=============================================== 03. Range Slider in Header Search  ===============================================

    $(".range-example-rating-input").asRange({
		range: true,
		limit: false,
		tip: true,
    });


=============================================== 04. Range Slider in Header Search  ===============================================


    $('.circle-rating-pro').circleProgress();

/*
=============================================== 05. Add to Favorites Menu ON/Off  ===============================================
*/
	$(".progression-studios-slider-more-options").hover(function() {
		var $this = $(".progression-studios-slider-more-options");
	    if ($this.hasClass('active')) {
	        $this.removeClass('active').addClass('hide');
	    } else {
	        $this.addClass('active');
	    }
	});


/*
=============================================== 06. Rating Selector  ===============================================
*/
	$('.rating-pro input').change(function () {
	  var $radio = $(this);
	  $('.rating-pro .selected').removeClass('active');
	  $radio.closest('label').addClass('active');
	});




});



var videoheader = $("#video-search-header");
$(function() {
$("#search-icon-more").on("click", function() {
	if($("#search-bar").val().length>0) {
		$("#video-search-header-filtering-padding").submit();
	}
});
});



$(function() {
$(".plTitle, #howtoaccess, .howtoaccess ").on("click", function() {
	$('#LoginModal').modal('toggle');

})

});



/*
* jquery-match-height 0.7.2 by @liabru
* http://brm.io/jquery-match-height/
* License MIT
*/
!function(t){"use strict";"function"==typeof define&&define.amd?define(["jquery"],t):"undefined"!=typeof module&&module.exports?module.exports=t(require("jquery")):t(jQuery)}(function(t){var e=-1,o=-1,n=function(t){return parseFloat(t)||0},a=function(e){var o=1,a=t(e),i=null,r=[];return a.each(function(){var e=t(this),a=e.offset().top-n(e.css("margin-top")),s=r.length>0?r[r.length-1]:null;null===s?r.push(e):Math.floor(Math.abs(i-a))<=o?r[r.length-1]=s.add(e):r.push(e),i=a}),r},i=function(e){var o={
byRow:!0,property:"height",target:null,remove:!1};return"object"==typeof e?t.extend(o,e):("boolean"==typeof e?o.byRow=e:"remove"===e&&(o.remove=!0),o)},r=t.fn.matchHeight=function(e){var o=i(e);if(o.remove){var n=this;return this.css(o.property,""),t.each(r._groups,function(t,e){e.elements=e.elements.not(n)}),this}return this.length<=1&&!o.target?this:(r._groups.push({elements:this,options:o}),r._apply(this,o),this)};r.version="0.7.2",r._groups=[],r._throttle=80,r._maintainScroll=!1,r._beforeUpdate=null,
r._afterUpdate=null,r._rows=a,r._parse=n,r._parseOptions=i,r._apply=function(e,o){var s=i(o),h=t(e),l=[h],c=t(window).scrollTop(),p=t("html").outerHeight(!0),u=h.parents().filter(":hidden");return u.each(function(){var e=t(this);e.data("style-cache",e.attr("style"))}),u.css("display","block"),s.byRow&&!s.target&&(h.each(function(){var e=t(this),o=e.css("display");"inline-block"!==o&&"flex"!==o&&"inline-flex"!==o&&(o="block"),e.data("style-cache",e.attr("style")),e.css({display:o,"padding-top":"0",
"padding-bottom":"0","margin-top":"0","margin-bottom":"0","border-top-width":"0","border-bottom-width":"0",height:"100px",overflow:"hidden"})}),l=a(h),h.each(function(){var e=t(this);e.attr("style",e.data("style-cache")||"")})),t.each(l,function(e,o){var a=t(o),i=0;if(s.target)i=s.target.outerHeight(!1);else{if(s.byRow&&a.length<=1)return void a.css(s.property,"");a.each(function(){var e=t(this),o=e.attr("style"),n=e.css("display");"inline-block"!==n&&"flex"!==n&&"inline-flex"!==n&&(n="block");var a={
display:n};a[s.property]="",e.css(a),e.outerHeight(!1)>i&&(i=e.outerHeight(!1)),o?e.attr("style",o):e.css("display","")})}a.each(function(){var e=t(this),o=0;s.target&&e.is(s.target)||("border-box"!==e.css("box-sizing")&&(o+=n(e.css("border-top-width"))+n(e.css("border-bottom-width")),o+=n(e.css("padding-top"))+n(e.css("padding-bottom"))),e.css(s.property,i-o+"px"))})}),u.each(function(){var e=t(this);e.attr("style",e.data("style-cache")||null)}),r._maintainScroll&&t(window).scrollTop(c/p*t("html").outerHeight(!0)),
this},r._applyDataApi=function(){var e={};t("[data-match-height], [data-mh]").each(function(){var o=t(this),n=o.attr("data-mh")||o.attr("data-match-height");n in e?e[n]=e[n].add(o):e[n]=o}),t.each(e,function(){this.matchHeight(!0)})};var s=function(e){r._beforeUpdate&&r._beforeUpdate(e,r._groups),t.each(r._groups,function(){r._apply(this.elements,this.options)}),r._afterUpdate&&r._afterUpdate(e,r._groups)};r._update=function(n,a){if(a&&"resize"===a.type){var i=t(window).width();if(i===e)return;e=i;
}n?o===-1&&(o=setTimeout(function(){s(a),o=-1},r._throttle)):s(a)},t(r._applyDataApi);var h=t.fn.on?"on":"bind";t(window)[h]("load",function(t){r._update(!1,t)}),t(window)[h]("resize orientationchange",function(t){r._update(!0,t)})});


$(function() {
	$(".sub-option").matchHeight();
	$('#form-signup').on('beforeSubmit', function()
{
    var $form = $(this);
    //console.log('before submit');

    var $submit = $form.find(':submit');
    $submit.html('Please wait <span class="fa fa-spin fa-spinner"></span>');
    $submit.prop('disabled', true);

});
})
